import { Injectable } from '@angular/core';
import { HttpClient,  HttpHeaders, HttpParams  } from '@angular/common/http';
import { environment } from '../../../../environments/enviroment';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UpdProfilProServiceService {
  _uploadImage:any
  URL=environment.urlBackend
  token:any=localStorage.getItem('token_Pro')
  header=new HttpHeaders().set('authorization',this.token)
  
  constructor(private http:HttpClient) { }

  updateprof(id:any,body:any){

    const token=localStorage.getItem('token_Pro')
    const headers=new HttpHeaders().set('authorization','Bearer '+token)

       return this.http.put<any>(`${this.URL}`+'doctor/'+id,body,{headers:headers});

  }
  updPhotoPro(id:any,body:any){

  
    const token=localStorage.getItem('token_Pro')
    const headers=new HttpHeaders().set('authorization','Bearer '+token)


       return this.http.put<any>(`${this.URL}`+'doctor/updatephoto/'+id,body,{headers:headers});

     }

    //  uploadImage(image){

    
    //     return this.http.post(`${this.URL}`+'uploadApi'+this._uploadImage, {image :image} );
    
    //   }

    uploadImage(image){

      /*   console.log("iiii",image) */
    
        return this.http.post("http://localhost:3000/uploadApi/", {image :image} );
    
      }
        


      updatePatientPhoto(id:any, photo:any){

      
          return this.http.put('http://localhost:3000/doctor/updatephoto/' +id , photo);
      
        }
        getProf(id: any): Observable<any> {
          const token = localStorage.getItem('token_Pro');
          const headers = new HttpHeaders().set('authorization', 'Bearer ' + token);
        
          return this.http.get<any>(`${this.URL}` + 'doctor/' + id, { headers: headers }).pipe(
            shareReplay({ bufferSize: 1, refCount: true })
          );
        }



        updatePasswordOnly(id,body){
          return this.http.put<any>(`${this.URL}doctor/update/passwordonly/${id}`,body)
        }

        updateDataOnly(id,body){
          return this.http.put(`${this.URL}doctor/update/dataonly/${id}`,body)
        }
}
