
          <div class="card-body">
              <form class="inscrit-content" [formGroup]="registerFormPat" (ngSubmit)="onSubmit()">


                <div class="form-row">
                  <!-- <h1 >Patient</h1> -->

                  <div class="col-md-3">
                    <mat-form-field>
                      <input matInput placeholder="{{ 'AUTH.NOM' | translate }}" type="text" formControlName="name" class="form-control"
                        [ngClass]="{ 'is-invalid': fPat.name.touched && fPat.name.errors }"  />
                        <mat-error *ngIf="fPat.name.invalid && fPat.name.touched">
                          <span *ngIf="fPat.name.errors?.required">{{
                            "Nom obligatoire"
                            }}</span>
                          <span *ngIf="fPat.name.errors?.minlength">{{
                            "Minimum 2 caractères"
                            }}</span>
                        </mat-error>
                    </mat-form-field>
                  </div>




                  <div class="col-md-3">
                    <mat-form-field>
                      <input matInput placeholder="{{ 'AUTH.PRENOM' | translate }}" type="text" formControlName="lastname" class="form-control"
                        [ngClass]="{ 'is-invalid': fPat.lastname.touched && fPat.lastname.errors }"  />
                        <mat-error *ngIf="fPat.lastname.invalid && fPat.lastname.touched">
                          <span *ngIf="fPat.lastname.errors?.required">{{
                            "Nom obligatoire"
                            }}</span>
                          <span *ngIf="fPat.lastname.errors?.minlength">{{
                            "Minimum 2 caractères"
                            }}</span>
                        </mat-error>
                    </mat-form-field>
                  </div>

                
             
            

                  <div class="col-md-3">
                    <mat-form-field class="profile-detail">
                      <mat-label>{{ "AUTH.DATEN" | translate }}</mat-label>
  
  
                        <input matInput  [matDatepicker]="picker" placeholder="{{ 'AUTH.DATEN' | translate }}" formControlName="birthday" class="form-control"
                        [ngClass]="{ 'is-invalid': fPat.birthday.touched && fPat.birthday.errors }"  />
  
                        
                      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-datepicker #picker></mat-datepicker>
        
                      <mat-error *ngIf="fPat.birthday.invalid && fPat.birthday.touched">
                        <span *ngIf="fPat.birthday.errors?.required">{{
                          "Date de naissance est obligatoire mm/dd/yyyy"
                          }}</span>
                      </mat-error>
                      <!-- Error message for pastDateValidator -->
                      <mat-error *ngIf="fPat.birthday.errors?.pastDate">
                        Date invalide
                      </mat-error>
  
                    </mat-form-field>
                  </div>
                  
                  


                  
                  <div class="col-md-3">
                    <mat-form-field>
                      <input matInput placeholder="SSN" type="number" formControlName="ssn" class="form-control" type="number"
                        [ngClass]="{ 'is-invalid': fPat.ssn.invalid && fPat.ssn.touched }" />
                      
                      <div *ngIf="fPat.ssn.invalid && fPat.ssn.touched" class="invalid-feedback">
                        <div *ngIf="fPat.ssn.errors?.required">Le SSN est obligatoire</div>
                        <div *ngIf="fPat.ssn.errors?.ssnLength">Le SSN doit comporter 9 chiffres</div>
                        <div *ngIf="fPat.ssn.errors?.pattern"> uniquement des chiffres</div>

                      </div>
                    </mat-form-field>
                    
                  </div>
                </div>

                
                <div class="form-row">

                

                  <div class="col-md-3">

                    <mat-form-field class="profile-detail">
                      <mat-label>{{ "AUTH.ADDRESS" | translate }}</mat-label>
        
                      <input matInput placeholder="{{ 'AUTH.ADDRESS' | translate }}" formControlName="adresse" class="form-control"
                      [ngClass]="{ 'is-invalid': fPat.adresse.touched && fPat.adresse.errors }" />
                  

                      <!-- Message d'erreur pour Validators.minLength -->
                      <mat-error *ngIf="fPat.adresse.errors?.minlength">
                        L'adresse doit comporter au moins 2 caractères
                      </mat-error>
                      <mat-error *ngIf="fPat.adresse.errors?.required">
                        L'adresse est obligatoire
                      </mat-error>
                    </mat-form-field>

                    

                  </div>

                  <div class="col-md-3">
                    <mat-form-field class="profile-detail">
                      <mat-label>{{ "AUTH.TELE" | translate }}</mat-label>
                      <input matInput placeholder="{{ 'AUTH.PRENOM' | translate }}" formControlName="tel" class="form-control" type="number"
                        [ngClass]="{ 'is-invalid': fPat.tel.touched && fPat.tel.errors }" />
                    
                      <!-- Custom error message for tel validation -->
                      <div *ngIf="fPat.tel.invalid && fPat.tel.touched" class="invalid-feedback">
                        <div *ngIf="fPat.tel.errors?.required">Le numéro de téléphone est obligatoire</div>
                        <div *ngIf="fPat.tel.errors?.telLength">Veuillez entre entre 8 et 16  chiffres</div>
                      </div>
                    </mat-form-field>

                    
                  </div>

                
               

                

                  <div class="col-md-3">





                    <mat-form-field>
                      <input matInput placeholder="{{ 'AUTH.POID' | translate }}" type="text" formControlName="weight" class="form-control" type="number"
                        [ngClass]="{ 'is-invalid': fPat.weight.invalid && fPat.weight.touched }" />
                    
                      <div *ngIf="fPat.weight.invalid && fPat.weight.touched" class="invalid-feedback">
                        <div *ngIf="fPat.weight.errors?.required">Le Poids est obligatoire</div>
                        <div *ngIf="fPat.weight.errors?.sizeLength">3 chiffres maximum</div>


                      </div>
                    </mat-form-field>
                    


                  </div>
                  <div class="col-md-3">




                    <mat-form-field>
                      <input matInput placeholder="{{ 'AUTH.SIZE' | translate }}" type="text" formControlName="size" class="form-control" type="number"
                        [ngClass]="{ 'is-invalid': fPat.size.invalid && fPat.size.touched }" />
                    
                      <div *ngIf="fPat.size.invalid && fPat.size.touched" class="invalid-feedback">
                        <div *ngIf="fPat.size.errors?.required">Taille obligatoire</div>
                        <div *ngIf="fPat.size.errors?.sizeLength">3 chiffres maximum</div>

                      </div>
                    </mat-form-field>




                  </div>


                </div>

                
                <div class="form-row">





                  <div class="col-md-3">

                    <mat-form-field>
                      <input matInput placeholder="{{ 'AUTH.EMAIL' | translate }}" type="email" formControlName="email" class="form-control"
                        [ngClass]="{ 'is-invalid': fPat.email.invalid && fPat.email.touched }" />
                      
                      <div *ngIf="fPat.email.invalid && fPat.email.touched" class="invalid-feedback">
                        <div *ngIf="fPat.email.errors?.required">Email est obligatoire</div>
                        <div *ngIf="fPat.email.errors?.email">Adresse email invalide</div>
                      </div>
                    </mat-form-field>

                  </div>

                  <div class="col-md-3">
                    <mat-radio-group aria-label="Select an option" >
                      <div class="float-child"style="width: 100px;" > 
                        <mat-radio-button value="homme" formControlName="gender" class="form-control" 
                          [ngClass]="{ 'is-invalid': submitted && fPat.gender.errors }" ngDefaultControl>{{ 'AUTH.SEXEH' | translate }}
                        </mat-radio-button>
                        <div *ngIf="submitted && fPat.gender.errors" class="invalid-feedback">
                        </div>
                      </div>
                      <div class="float-child" style="width: 100px;">

                        <mat-radio-button value="femme" formControlName="gender" class="form-control"
                          [ngClass]="{ 'is-invalid': submitted && fPat.gender.errors }" ngDefaultControl>{{ 'AUTH.SEXEF' | translate }}
                        </mat-radio-button>
                        <div *ngIf="submitted && fPat.gender.errors" class="invalid-feedback">
                        </div>
                      </div>
                    </mat-radio-group>
                  
                  </div>
                  <div class="col-md-3">
                    <mat-form-field>
                      <input #password matInput placeholder="{{ 'AUTH.MOTDEPASS' | translate }}" [type]="hide ? 'password' : 'text'"
                        formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': fPat.password.invalid && fPat.password.touched }" />
                      
                      <!-- Mat-hint shows the password length -->
                      <mat-hint align="end" aria-live="polite">
                        {{ password.value.length }} / {{ passwordComponent.max }}
                      </mat-hint>
                      
                      <!-- Mat-icon toggles password visibility -->
                      <mat-icon (click)="myFunction()" matSuffix>{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
                      
                      <!-- Mat-error displays password validation errors -->
                      <mat-error *ngIf="fPat.password.invalid && fPat.password.touched">
                        <div *ngIf="fPat.password.errors?.required">{{ 'errors.required.passwordRequired' | translate }}</div>
                        <div *ngIf="fPat.password.errors?.minlength">{{ 'errors.required.passwordLength' | translate }}</div>
                        <div *ngIf="fPat.password.errors?.pattern">{{ 'errors.required.passwordComplexity' | translate }}</div>
                      </mat-error>
                      
                      <!-- Mat-password-strength displays password strength -->
                      <mat-password-strength #passwordComponent (onStrengthChanged)="onStrengthChanged($event)" [password]="password.value">
                      </mat-password-strength>
                    </mat-form-field>
                  </div>
                  
                  <div class="col-md-3">
                    <mat-form-field>
                      <input  matInput placeholder="{{ 'AUTH.CFM' | translate }}" [type]="hide ? 'password' : 'text'"
                        formControlName="confirmPassword" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && fPat.confirmPassword.errors }" />
                      

                     
                        <mat-error *ngIf="registerFormPat.controls['confirmPassword'].value!=registerFormPat.controls['password'].value">
                          <div >Confirmer votre mot de passe</div>
                        </mat-error>
                    </mat-form-field>
                  </div>
                </div>

                
    

                

                    <div class="text-center" style="margin-top: 20px;"> 
                      <button class="btn btn-primary mr-1" type="submit"  >{{ 'AUTH.INSCRIRE' | translate }}</button>
                   <!--    <button mat-raised-button color="primary" (click)="signInWithGoogle()"  type="submit" style="margin-right: 10px">
                        {{ 'AUTH.CG' | translate }}
                      </button> -->
                    </div>
                  
             
                 

             
               

               
 
          
              

            



                
              </form>
              
              
            </div>

  
    

