<mat-dialog-content style="height:auto ;">
  <div class="login-page">

    <mat-tab-group mat-stretch-tabs class="example-stretched-tabs">

      <mat-tab label="{{ 'AUTH.IDENTIFIER2' | translate }}">
        <br>
        <mat-card-content class="mat-card-content-reg" class="scroller" #scroller1>

        <div class="col-sm-3 col-xl-12 text-center reg-toggle mx-auto">
            <mat-button-toggle-group appearance="legacy" name="fontStyle" aria-label="Font Style"
              #group="matButtonToggleGroup" [value]="selectedVal" (change)="onValChange(group.value)">
              <mat-button-toggle class="role-toggle" value="Professionnel" (click)="onReset()" class="toggle-button">
<i
                style="color: rgb(236, 234, 232);" class="align-middle mr-2 fa fa-user-md"></i>{{ 'AUTH.PROF' | translate }}</mat-button-toggle>
              <mat-button-toggle class="role-toggle" value="Patient" (click)="onReset()" class="toggle-button">
<i
                style="color:rgb(236, 234, 232);" class="align-middle mr-2 fa fa-user-injured"></i>{{ 'AUTH.PAT' | translate }}</mat-button-toggle>
            </mat-button-toggle-group>
          </div>    

        <br>

          <div class="switch" [ngSwitch]="selectedVal">
<!-- *************************************************LOGIN FOR PROFESSIONNEL******************************************************************** -->
            <div class="proReg" *ngSwitchCase="'Professionnel'">


              <form [formGroup]="loginFormPro" (ngSubmit)="loginPro(loginFormPro)">


                <mat-form-field>
                  <input matInput placeholder="Email" formControlName="email" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && flogPro.email.errors }">
                  <mat-error *ngIf="flogPro.email.invalid">Email requis</mat-error>
                  <mat-icon matSuffix>email</mat-icon>
                </mat-form-field>

                <mat-form-field>
                  <input matInput placeholder="{{ 'AUTH.MOTDEPASS' | translate }}" [type]="hide ? 'password' : 'text'"
                    formControlName="password" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && flogPro.password.errors }">
                  <mat-icon (click)="myFunction()" matSuffix>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                  <mat-error *ngIf="flogPro.password.invalid">Mot de passe requis </mat-error>
                </mat-form-field>

                <a routerLink="/forgot-password">{{ 'UPDATEMOT.MO' | translate }}</a>


                <div style="display:flex; flex-direction: row; justify-content: center; align-items: center">

                  <button mat-raised-button color="primary" class="login-button" type="submit" style="width:40% ;">
                    {{ 'AUTH.SIGNIN' | translate }}
                  </button>  <br>

                </div>





              </form>
            </div>

<!-- *************************************************LOGIN FOR PATIENT******************************************************************** -->

            <div class="proReg" *ngSwitchCase="'Patient'">

              <form [formGroup]="loginFormPat" (ngSubmit)="loginPat(loginFormPat)">


                <mat-form-field>
                  <input matInput placeholder="Email" formControlName="email" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && flogPat.email.errors }">
                  <mat-error *ngIf="flogPat.email.invalid">Email requis</mat-error>
                  <mat-icon matSuffix>email</mat-icon>
                </mat-form-field>

                <mat-form-field>
                  <input matInput placeholder="{{ 'AUTH.MOTDEPASS' | translate }}" [type]="hide ? 'password' : 'text'"
                    formControlName="password" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && flogPat.password.errors }">
                  <mat-icon (click)="myFunction()" matSuffix>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                  <mat-error *ngIf="flogPat.password.invalid">Mot de passe requis </mat-error>
                </mat-form-field>

                <a routerLink="/forgot-password">{{ 'UPDATEMOT.MO' | translate }}</a>
                <div style="display:flex; flex-direction: row; justify-content: center; align-items: center">

                  <button mat-raised-button color="primary" class="login-button" type="submit" style="width:40% ;">
                    {{ 'AUTH.SIGNIN' | translate }}
                  </button> <br>

                </div>

              </form>
            </div>


          </div>
        </mat-card-content>

      </mat-tab>
















      <mat-tab label="{{ 'AUTH.INSCRIRE' | translate }}">
        <br>
        <mat-card-content class="mat-card-content-reg" class="scroller" #scroller1>
        <div class="col-sm-3 col-xl-12 text-center reg-toggle mx-auto">
            <mat-button-toggle-group appearance="legacy" name="fontStyle" aria-label="Font Style"
              #group2="matButtonToggleGroup" [value]="selectedVal2" (change)="onValChange2(group2.value)">
              <mat-button-toggle class="role-toggle" value="Professionnel" (click)="onReset()" class="toggle-button">
<i
                style="color: rgb(236, 234, 232);" class="align-middle mr-2 fa fa-user-md"></i>                professionnel</mat-button-toggle>
              <mat-button-toggle class="role-toggle" value="Patient" (click)="onReset()" class="toggle-button">
<i
                style="color:rgb(236, 234, 232);" class="align-middle mr-2 fa fa-user-injured"></i>                patient </mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <br>
          <div class="switch" [ngSwitch]="selectedVal2">

            <div class="proReg" *ngSwitchCase="'Professionnel'">

              <form class="inscrit-content" [formGroup]="registerFormPro" (ngSubmit)="registerPro(registerFormPro,2)">
                <div class="form-row">
                  <!-- <h1 >Professionnel</h1> -->




                  <!-- <div class="col-md-6">
                    <mat-form-field>
                      <input matInput placeholder="{{ 'AUTH.NOM' | translate }}" type="text" formControlName="name" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.name.errors }"          
                        (blur)="formatInput(registerFormPro, 'name')"
                        />
                      <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                        <div *ngIf="f.name.errors.required"> Nom obligatoire</div>
                      </div>
                    </mat-form-field>
                  </div> -->
                  
                  <div class="col-md-6">
                  <mat-form-field class="profile-detail">
                    <mat-label>{{ "AUTH.NOM" | translate }}</mat-label>
                

                    <input matInput placeholder="{{ 'AUTH.NOM' | translate }}" type="text" formControlName="name" class="form-control"
                    [ngClass]="{ 'is-invalid': f.name.touched && f.name.errors }"  />

                    <mat-error *ngIf="f.name.invalid && f.name.touched">
                      <span *ngIf="f.name.errors?.required">{{
                        "Nom obligatoire"
                        
                        }}</span>
                      <span *ngIf="f.name.errors?.minlength">{{
                        "Minimum 2 caractères"
                        }}</span>
                    </mat-error>
                  </mat-form-field>
                  </div>

                  <!-- <div class="col-md-6">
                    <mat-form-field>
                      <input matInput placeholder="{{ 'AUTH.PRENOM' | translate }}" type="text" formControlName="lastname"
                        class="form-control" [ngClass]="{ 'is-invalid': submitted && f.lastname.errors }"
                        (blur)="formatInput(registerFormPro, 'lastname')"
                        />
                        <div *ngIf="submitted && f.lastname.errors" class="invalid-feedback">
                          <div *ngIf="f.lastname.errors.required"> Prénom obligatoire</div>
                        </div>
                    </mat-form-field>
                  </div> -->




                  <div class="col-md-6">

                  <mat-form-field class="profile-detail">
                    <mat-label>{{ "AUTH.PRENOM" | translate }}</mat-label>

                    <input matInput placeholder="{{ 'AUTH.PRENOM' | translate }}" type="text" formControlName="lastname" class="form-control"
                    [ngClass]="{ 'is-invalid': f.name.touched && f.name.errors }"  />


                    <mat-error *ngIf="f.lastname.invalid && f.lastname.touched">
                      <span *ngIf="f.lastname.errors?.required">{{
                        "Prénom obligatoire"
                        }}</span>
                      <span *ngIf="f.lastname.errors?.minlength">{{
                        "Minimum 2 caractères"
                        }}</span>
                    </mat-error>
                  </mat-form-field>



                  </div>


                  <!-- <div class="col-md-6">
                    <mat-form-field appearance="fill">
                      <mat-label>{{ 'AUTH.DATEN' | translate }}</mat-label>
                      <input matInput [matDatepicker]="basicDatepicker" formControlName="birthday"
                        class="form-control" [ngClass]="{ 'is-invalid': submitted && f.birthday.errors }">
                      <div *ngIf="submitted && f.birthday.errors" class="invalid-feedback">
                        <div *ngIf="f.birthday.errors.required">date de naissance obligatoire</div>
                      </div>
                      <mat-hint>{{ 'AUTH.MM' | translate }}</mat-hint>
                      <mat-datepicker-toggle matSuffix [for]="basicDatepicker"></mat-datepicker-toggle>
                      <mat-datepicker #basicDatepicker></mat-datepicker>
                    </mat-form-field>
                  </div> -->



                  <div class="col-md-6">
                  <mat-form-field class="profile-detail">
                    <mat-label>{{ "AUTH.DATEN" | translate }}</mat-label>


                      <input matInput  [matDatepicker]="picker" placeholder="{{ 'AUTH.DATEN' | translate }}"  formControlName="birthday" class="form-control"
                      [ngClass]="{ 'is-invalid': f.birthday.touched && f.birthday.errors }"  />

                      
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
      
                    <mat-error *ngIf="f.birthday.invalid && f.birthday.touched">
                      <span *ngIf="f.birthday.errors?.required">{{
                        "Date de naissance est obligatoire mm/dd/yyyy"
                        }}</span>
                    </mat-error>
                    <!-- Error message for pastDateValidator -->
                    <mat-error *ngIf="f.birthday.errors?.pastDate">
                      Date invalide
                    </mat-error>

                  </mat-form-field>

                  </div>














                  <div class="col-md-6">


                    <mat-form-field class="profile-detail">
                      <mat-label>{{ "AUTH.TELE" | translate }}</mat-label>
                      <input matInput placeholder="{{ 'AUTH.PRENOM' | translate }}" formControlName="tel" class="form-control" type="number"
                        [ngClass]="{ 'is-invalid': f.tel.touched && f.tel.errors }" />
                    
                      <!-- Custom error message for tel validation -->
                      <div *ngIf="f.tel.invalid && f.tel.touched" class="invalid-feedback">
                        <div *ngIf="f.tel.errors?.required">Le numéro de téléphone est obligatoire</div>
                        <div *ngIf="f.tel.errors?.telLength">Veuillez entre entre 8 et 16  chiffres</div>
                      </div>
                    </mat-form-field>




                  </div>
                  <div class="col-md-6">

                    <mat-form-field class="profile-detail">
                      <mat-label>{{ "AUTH.ADDRESS" | translate }}</mat-label>
        
                      <input matInput placeholder="{{ 'AUTH.ADDRESS' | translate }}" formControlName="adresse" class="form-control"
                      [ngClass]="{ 'is-invalid': f.adresse.touched && f.adresse.errors }" />
                  

                      <!-- Message d'erreur pour Validators.minLength -->
                      <mat-error *ngIf="f.adresse.errors?.minlength">
                        L'adresse doit comporter au moins 2 caractères
                      </mat-error>
                      <mat-error *ngIf="f.adresse.errors?.required">
                        L'adresse est obligatoire
                      </mat-error>
                    </mat-form-field>


                  </div>
                  <div class="col-md-6">

                    <mat-form-field class="profile-detail">
                      <mat-label>FAX</mat-label>
                      <input matInput placeholder="FAX" formControlName="fax" class="form-control" type="number"
                        [ngClass]="{ 'is-invalid': f.fax.touched && f.fax.errors }" />
                    
                      <!-- Custom error message for fax validation -->
                      <div *ngIf="f.fax.invalid && f.fax.touched" class="invalid-feedback">
                        <div *ngIf="f.fax.errors?.required">fax est obligatoire</div>
                        <div *ngIf="f.fax.errors?.telLength">Veuillez entre entre 8 et 16  chiffres</div>
                      </div>
                    </mat-form-field>


                  </div>
                  <mat-form-field appearance="fill">
                    <mat-label>{{ 'AUTH.METIER' | translate }}</mat-label>
                    <mat-select formControlName="job"  [(ngModel)]="jobs[0].viewValue">
                      <mat-option *ngFor="let job of jobs" [value]="job.viewValue">
                        {{job.viewValue}}
                      </mat-option>
                    </mat-select>
                 <!--    <div *ngIf="submitted && f.lastname.errors" class="invalid-feedback">
                      <div *ngIf="f.job.errors.required">travail obligatoire</div>
                    </div> -->
                  </mat-form-field>
                  <div class="col-md-6">
                    <mat-form-field appearance="fill">
                      <mat-label>{{ 'AUTH.TITRE' | translate }}</mat-label>
                      <mat-select formControlName="title"  [(ngModel)]="titre[0].viewValue">
                        <mat-option *ngFor="let food of titre" [value]="food.viewValue">
                          {{food.viewValue}}
                        </mat-option>
                      </mat-select>
                   <!--    <div *ngIf="submitted && f.lastname.errors" class="invalid-feedback">
                        <div *ngIf="f.job.errors.required">travail obligatoire</div>
                      </div> -->
                    </mat-form-field>
                  </div>


                  <div class="col-md-6">
                    <!-- <mat-form-field>
                      <input matInput placeholder="RPPS" type="number" formControlName="rpps" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.rpps.errors }" />
                      <div *ngIf="submitted && f.lastname.errors" class="invalid-feedback">
                        <div *ngIf="f.rpps.errors.required">rpps obligatoire</div>
                      </div>
                    </mat-form-field> -->

                    <mat-form-field>
                      <input matInput placeholder="RPPS" type="number" formControlName="rpps" class="form-control"
                        [ngClass]="{ 'is-invalid': f.rpps.invalid && f.rpps.touched }" />
                      
                      <div *ngIf="f.rpps.invalid && f.rpps.touched" class="invalid-feedback">
                        <div *ngIf="f.rpps.errors?.required">Le RPPS est obligatoire</div>
                        <div *ngIf="f.rpps.errors?.rppsLength">Le RPPS doit comporter 12 chiffres</div>
                      </div>
                    </mat-form-field>




                  </div>



                  <div class="col-md-6">




                    <mat-form-field>
                      <input matInput placeholder="{{ 'AUTH.EMAIL' | translate }}" type="email" formControlName="email" class="form-control"
                        [ngClass]="{ 'is-invalid': f.email.invalid && f.email.touched }" />
                      
                      <div *ngIf="f.email.invalid && f.email.touched" class="invalid-feedback">
                        <div *ngIf="f.email.errors?.required">Email est obligatoire</div>
                        <div *ngIf="f.email.errors?.email">Adresse email invalide</div>
                      </div>
                    </mat-form-field>


                  </div>
                  <div class="col-md-6">

<!-- 
                    <mat-form-field>
                      <input #password matInput placeholder="{{ 'AUTH.MOTDEPASS' | translate }}" [type]="hide ? 'password' : 'text'"
                        formControlName="password" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                      <mat-hint align="end" aria-live="polite">
                        {{password.value.length}} / {{passwordComponent.max}}
                      </mat-hint>
                      <mat-icon (click)="myFunction()" matSuffix>{{hide ? 'visibility_off' : 'visibility'}}
                      </mat-icon>
                      <mat-error *ngIf="!registerFormPro.controls['password'].valid && registerFormPro.controls['password'].touched">
                        {{ getErrorPassword() }}
                      </mat-error>

                      <mat-password-strength #passwordComponent (onStrengthChanged)="onStrengthChanged($event)"
                        [password]="password.value">
                      </mat-password-strength>

                    </mat-form-field> -->


                    <mat-form-field>
                      <input #password matInput placeholder="{{ 'AUTH.MOTDEPASS' | translate }}" [type]="hide ? 'password' : 'text'"
                        formControlName="password" class="form-control"
                        [ngClass]="{ 'is-invalid': f.password.invalid && f.password.touched }" />
                    
                      <mat-hint align="end" aria-live="polite">
                        {{password.value.length}} / {{passwordComponent.max}}
                      </mat-hint>
                      <mat-icon (click)="myFunction()" matSuffix>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    
                      <mat-error *ngIf="!f.password.valid && f.password.touched">
                        {{ getErrorPassword() }}
                      </mat-error>
                    
                      <mat-password-strength #passwordComponent (onStrengthChanged)="onStrengthChanged($event)"
                        [password]="password.value">
                      </mat-password-strength>
                    
                      <div *ngIf="f.password.invalid && f.password.touched" class="invalid-feedback">
                        <div *ngIf="f.password.errors?.required">Mot de passe est obligatoire</div>
                        <div *ngIf="f.password.errors?.minlength">8 caractères</div>
                        <div *ngIf="f.password.errors?.pattern">Le mot de passe doit être plus complexe [a-z][A-Z][@$!%*?&]</div>
                      </div>
                    </mat-form-field>





                  </div>
                  <div class="col-md-6">
                    <mat-form-field>
                      <input matInput placeholder="{{ 'AUTH.CFM' | translate }}" [type]="hide ? 'password' : 'text'"
                        formControlName="confirmPassword" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }" />


                        <mat-error *ngIf="registerFormPro.controls['confirmPassword'].value!=registerFormPro.controls['password'].value">
                          <div >Confirmer votre mot de passe</div>
                        </mat-error>
                    </mat-form-field>
                  </div>
                </div>

                <div class="form-group form-check">
                  <mat-icon matSuffix (click)="openAlertDialog()" >add</mat-icon>
                   
                  <label for="acceptTerms" class="form-check-label" (click)="openAlertDialog()">{{ 'AUTH.ACCEPTT' | translate }}</label>
                  <div *ngIf="submitted && f.acceptTerms.errors" class="invalid-feedback">Accepter Ts & Cs is
                    obligatoire</div>
                </div>
                <div class="text-center">
                  <button class="btn btn-primary mr-1" type="submit" [disabled]="!acceptTerme || !registerFormPro">{{ 'AUTH.INSCRIRE' | translate }}</button>
               
        <!--       <button mat-raised-button color="primary" (click)="signInWithGoogle()" type="submit" style="margin-right: 10px">
                {{ 'AUTH.CG' | translate }}
              </button> -->
                </div>
              </form>
            </div>







            

            <!-- ***********************patient register******************************************************** -->

            <div class="patReg" *ngSwitchCase="'Patient'">
              <form class="inscrit-content" [formGroup]="registerFormPat" (ngSubmit)="registerPat(registerFormPat,1)">


                <div class="form-row">
                  <!-- <h1 >Patient</h1> -->


                  <div class="col-md-6">
                    <mat-form-field>
                      <input matInput placeholder="{{ 'AUTH.NOM' | translate }}" type="text" formControlName="name" class="form-control"
                        [ngClass]="{ 'is-invalid': fPat.name.touched && fPat.name.errors }" (blur)="formatInput(registerFormPat, 'name')" />
                        <mat-error *ngIf="fPat.name.invalid && fPat.name.touched">
                          <span *ngIf="fPat.name.errors?.required">{{
                            "Nom obligatoire"
                            }}</span>
                          <span *ngIf="fPat.name.errors?.minlength">{{
                            "Minimum 2 caractères"
                            }}</span>
                        </mat-error>
                    </mat-form-field>
                  </div>




                  <div class="col-md-6">
                    <mat-form-field>
                      <input matInput placeholder="{{ 'AUTH.PRENOM' | translate }}" type="text" formControlName="lastname" class="form-control"
                        [ngClass]="{ 'is-invalid': fPat.lastname.touched && fPat.lastname.errors }" (blur)="formatInput(registerFormPat, 'lastname')" />
                        <mat-error *ngIf="fPat.lastname.invalid && fPat.lastname.touched">
                          <span *ngIf="fPat.lastname.errors?.required">{{
                            "Nom obligatoire"
                            }}</span>
                          <span *ngIf="fPat.lastname.errors?.minlength">{{
                            "Minimum 2 caractères"
                            }}</span>
                        </mat-error>
                    </mat-form-field>
                  </div>


             

                  <div class="col-md-6">
                    <mat-form-field class="profile-detail">
                      <mat-label>{{ "AUTH.DATEN" | translate }}</mat-label>
  
  
                        <input matInput  [matDatepicker]="picker" placeholder="{{ 'AUTH.DATEN' | translate }}" formControlName="birthday" class="form-control"
                        [ngClass]="{ 'is-invalid': fPat.birthday.touched && fPat.birthday.errors }"  />
  
                        
                      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-datepicker #picker></mat-datepicker>
        
                      <mat-error *ngIf="fPat.birthday.invalid && fPat.birthday.touched">
                        <span *ngIf="fPat.birthday.errors?.required">{{
                          "Date de naissance est obligatoire mm/dd/yyyy"
                          }}</span>
                      </mat-error>
                      <!-- Error message for pastDateValidator -->
                      <mat-error *ngIf="fPat.birthday.errors?.pastDate">
                        Date invalide
                      </mat-error>
  
                    </mat-form-field>
                  </div>
                  
                  


                  
                  <div class="col-md-6">
                    <mat-form-field>
                      <input matInput placeholder="SSN" type="number" formControlName="ssn" class="form-control" type="number"
                        [ngClass]="{ 'is-invalid': fPat.ssn.invalid && fPat.ssn.touched }" />
                      
                      <div *ngIf="fPat.ssn.invalid && fPat.ssn.touched" class="invalid-feedback">
                        <div *ngIf="fPat.ssn.errors?.required">Le SSN est obligatoire</div>
                        <div *ngIf="fPat.ssn.errors?.ssnLength">Le SSN doit comporter 9 chiffres</div>
                        <div *ngIf="fPat.ssn.errors?.pattern"> uniquement des chiffres</div>

                      </div>
                    </mat-form-field>
                    
                  </div>
                  


                  <div class="col-md-6">

                    <mat-form-field class="profile-detail">
                      <mat-label>{{ "AUTH.ADDRESS" | translate }}</mat-label>
        
                      <input matInput placeholder="{{ 'AUTH.ADDRESS' | translate }}" formControlName="adresse" class="form-control"
                      [ngClass]="{ 'is-invalid': fPat.adresse.touched && fPat.adresse.errors }" />
                  

                      <!-- Message d'erreur pour Validators.minLength -->
                      <mat-error *ngIf="fPat.adresse.errors?.minlength">
                        L'adresse doit comporter au moins 2 caractères
                      </mat-error>
                      <mat-error *ngIf="fPat.adresse.errors?.required">
                        L'adresse est obligatoire
                      </mat-error>
                    </mat-form-field>


                  </div>


                  <div class="col-md-6">

                    <div class="float-container">
                      <mat-radio-group aria-label="Select an option">
                        <div class="float-child">
                          <mat-radio-button value="homme" formControlName="gender" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && fPat.gender.errors }" ngDefaultControl>{{ 'AUTH.SEXEH' | translate }}
                          </mat-radio-button>
                          <div *ngIf="submitted && fPat.gender.errors" class="invalid-feedback">
                          </div>
                        </div>
                        <div class="float-child">

                          <mat-radio-button value="femme" formControlName="gender" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && fPat.gender.errors }" ngDefaultControl>{{ 'AUTH.SEXEF' | translate }}
                          </mat-radio-button>
                          <div *ngIf="submitted && fPat.gender.errors" class="invalid-feedback">
                          </div>
                        </div>
                      </mat-radio-group>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <mat-form-field class="profile-detail">
                      <mat-label>{{ "AUTH.TELE" | translate }}</mat-label>
                      <input matInput placeholder="{{ 'AUTH.TELE' | translate }}" formControlName="tel" class="form-control" type="number"
                        [ngClass]="{ 'is-invalid': fPat.tel.touched && fPat.tel.errors }" />
                    
                      <!-- Custom error message for tel validation -->
                      <div *ngIf="fPat.tel.invalid && fPat.tel.touched" class="invalid-feedback">
                        <div *ngIf="fPat.tel.errors?.required">Le numéro de téléphone est obligatoire</div>
                        <div *ngIf="fPat.tel.errors?.telLength">Veuillez entre entre 8 et 16  chiffres</div>
                      </div>
                    </mat-form-field>

                    
                  </div>


                  <div class="col-md-6">





                    <mat-form-field>
                      <input matInput placeholder="{{ 'AUTH.POID' | translate }}" type="text" formControlName="weight" class="form-control" type="number"
                        [ngClass]="{ 'is-invalid': fPat.weight.invalid && fPat.weight.touched }" />
                    
                      <div *ngIf="fPat.weight.invalid && fPat.weight.touched" class="invalid-feedback">
                        <div *ngIf="fPat.weight.errors?.required">Le Poids est obligatoire</div>
                        <div *ngIf="fPat.weight.errors?.weightRange">La valeur saisie est invalide.</div>


                      </div>
                    </mat-form-field>
                    


                  </div>




                  <div class="col-md-6">




                    <mat-form-field>
                      <input matInput placeholder="{{ 'AUTH.SIZE' | translate }}" type="text" formControlName="size" class="form-control" type="number"
                        [ngClass]="{ 'is-invalid': fPat.size.invalid && fPat.size.touched }" />
                    
                      <div *ngIf="fPat.size.invalid && fPat.size.touched" class="invalid-feedback">
                        <div *ngIf="fPat.size.errors?.required">Taille obligatoire</div>
                        <div *ngIf="fPat.size.errors?.sizeRange">La valeur saisie est invalide.</div>

                      </div>
                    </mat-form-field>




                  </div>





                  <div class="col-md-6">

                    <mat-form-field>
                      <input matInput placeholder="{{ 'AUTH.EMAIL' | translate }}" type="email" formControlName="email" class="form-control"
                        [ngClass]="{ 'is-invalid': fPat.email.invalid && fPat.email.touched }" />
                      
                      <div *ngIf="fPat.email.invalid && fPat.email.touched" class="invalid-feedback">
                        <div *ngIf="fPat.email.errors?.required">Email est obligatoire</div>
                        <div *ngIf="fPat.email.errors?.email">Adresse email invalide</div>
                      </div>
                    </mat-form-field>








                  </div>
                  <div class="col-md-6">
                    <mat-form-field>
                      <input #password matInput placeholder="{{ 'AUTH.MOTDEPASS' | translate }}" [type]="hide ? 'password' : 'text'"
                        formControlName="password" class="form-control"
                        [ngClass]="{ 'is-invalid': fPat.password.invalid && fPat.password.touched }" />
                    
                      <mat-hint align="end" aria-live="polite">
                        {{password.value.length}} / {{passwordComponent.max}}
                      </mat-hint>
                      <mat-icon (click)="myFunction()" matSuffix>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    
                      <mat-error *ngIf="!fPat.password.valid && fPat.password.touched">
                        {{ getErrorPassword() }}
                      </mat-error>
                    
                      <mat-password-strength #passwordComponent (onStrengthChanged)="onStrengthChanged($event)"
                        [password]="password.value">
                      </mat-password-strength>
                    
                      <div *ngIf="fPat.password.invalid && fPat.password.touched" class="invalid-feedback">
                        <div *ngIf="fPat.password.errors?.required">Mot de passe est obligatoire</div>
                        <div *ngIf="fPat.password.errors?.minlength">8 caractères</div>
                        <div *ngIf="fPat.password.errors?.pattern">Le mot de passe doit être plus complexe [a-z][A-Z][@$!%*?&]</div>
                      </div>
                    </mat-form-field>
                    
                  </div>
                  <div class="col-md-6">
                    <mat-form-field>
                      <input  matInput placeholder="{{ 'AUTH.CFM' | translate }}" [type]="hide ? 'password' : 'text'"
                        formControlName="confirmPassword" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && fPat.confirmPassword.errors }" />
                      

                     
                        <mat-error *ngIf="registerFormPat.controls['confirmPassword'].value!=registerFormPat.controls['password'].value">
                          <div >Confirmer votre mot de passe</div>
                        </mat-error>
                    </mat-form-field>
                  </div>
                </div>
              

            

                <div class="form-group form-check">
                  <!--       <input type="checkbox" formControlName="acceptTerms" id="acceptTerms" class="form-check-input"
                            [ngClass]="{ 'is-invalid': submitted && fPat.acceptTerms.errors }" /> -->
                            <mat-icon matSuffix (click)="openAlertDialog()" >add</mat-icon>
                          <label for="acceptTerms" class="form-check-label" (click)="openAlertDialog()">{{ 'AUTH.ACCEPTT' | translate }}</label>
                          <div *ngIf="submitted && fPat.acceptTerms.errors" class="invalid-feedback">Accept Ts & Cs is
                            required</div>
                        </div>
                        <div class="text-center"> 
                          <button class="btn btn-primary mr-1" type="submit" [disabled]="!acceptTerme" [mat-dialog-close]="true">{{ 'AUTH.INSCRIRE' | translate }}</button>
                       <!--    <button mat-raised-button color="primary" (click)="signInWithGoogle()"  type="submit" style="margin-right: 10px">
                            {{ 'AUTH.CG' | translate }}
                          </button> -->
                        </div>
              </form>


            </div>
          </div>


        </mat-card-content>
      </mat-tab>




    </mat-tab-group>
  </div>


</mat-dialog-content>


