import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { LayoutsRoutingModule } from './layouts-routing.module';
import { FrontLayoutComponent } from './front-layout/front-layout.component';
import { RouterModule } from '@angular/router';
import { MatIconModule} from '@angular/material/icon';
import { PatientLayoutComponent } from './patient-layout/patient-layout.component';
import { ProfessionnelLayoutComponent } from './professionnel-layout/professionnel-layout.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSidenavModule } from '@angular/material/sidenav'; // Import MatSidenavModule
import { MatDividerModule } from '@angular/material/divider';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslateModule } from '@ngx-translate/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import {BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ConnectionServiceModule } from 'ng-connection-service';
import { PartialsModule } from '../partials/partials.module';




@NgModule({
  declarations: [FrontLayoutComponent, PatientLayoutComponent, ProfessionnelLayoutComponent],
  imports: [
    CommonModule,BrowserAnimationsModule,
    LayoutsRoutingModule,HttpClientModule,TranslateModule,
    RouterModule,
    MatIconModule,PartialsModule,
    MatProgressBarModule,MatSidenavModule,MatListModule,MatDividerModule,MatToolbarModule,MatButtonModule,MatMenuModule,MatInputModule,MatSelectModule,MatFormFieldModule,FormsModule,ConnectionServiceModule

  ]
})
export class LayoutsModule { }
