import {
  Component, OnInit, ElementRef,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Validators } from '@angular/forms';
import { LoginService } from '../../services/login.service'
import { AuthPatientService } from '../../services/patient/auth-patient.service'
import { AuthProfessionnelService } from '../../services/professionnel/auth-professionnel.service'
import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';


import {
  Router,
  Event as RouterEvent,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError
} from '@angular/router';
import { SignatureComponent } from 'src/app/partials/signature/signature.component';

import SignaturePad from 'signature_pad';
import Swal from 'sweetalert2'
import { UpdProfilPatientService } from '../../services/patient/upd-profil-patient.service';
import { jobData } from '../../interfaces/job-data'
import { Patient } from '../../interfaces/patient.interface';
import { Professional } from '../../interfaces/professionnel.interface';
import { MatDialog } from '@angular/material/dialog';
import {pastDateValidator} from '../../helper/pastDateValidator'
import {MustMatch} from '../../helper/MustMatch'
import { ssnLengthValidator, rppsLengthValidator, telFaxLengthValidator, sizeRangeValidator, weightRangeValidator, } from '../../helper/custom-validators';

  
/**
 * @title AuthComponent
 * @description AuthComponent representing the authentication component in the application.
 */

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css']

})
export class AuthComponent implements OnInit  {
  // user!:SocialUser;
  @ViewChild('canvas', { static: true }) canvas: ElementRef;
  sig: SignaturePad;
  imgPad;
  acceptTerme = false;
  jobs = jobData;
  doctor:Professional;
  patient:Patient

  clear() {
    this.sig.clear();
  }
  titre = [
    { value: 'professionnel', viewValue: 'Pr' },
    { value: 'doctor', viewValue: 'Dr ' },
    { value: 'mester', viewValue: 'Mr' },
    { value: 'miss', viewValue: 'Mme ' },
  ]
  download() {
    //console.log(this.sig.toDataURL('image/svg+xml'));
    this.updateservice.uploadImage(this.sig.toDataURL('image/svg+xml')).subscribe((result) => {
      this.imgPad = result
      /*     console.log(result) */
    })
  }
  getErrorPassword(){}
  messageError: any;
  registerFormPro: FormGroup;
  registerFormPat: FormGroup;
  loginFormPro: FormGroup;
  loginFormPat: FormGroup;
  submitted = false;
  public selectedVal = "Professionnel";
  public selectedVal2 = "Professionnel";
  result: any;
  myDate = new Date();
  datatoken: any;
  dataResponse: any;
  status: any;
  action: any;
  showDetails: boolean;
  public showOverlay = true;
  hide: boolean = true;
  loading: boolean = false;
  points = [];
  
  signatureImage;
  public innerWidth: any;
  showImage(data) {
    this.signatureImage = data;
  }
  public onValChange(val: any) {
    this.selectedVal = val;
   //    console.log(this.selectedVal)
    this.status = (this.action + this.selectedVal)


  }

  
  hidePassword() {
    this.hide = !this.hide;
  }
  constructor(
    private datePipe: DatePipe,
    public updateservice: UpdProfilPatientService,
    private formBuilder: FormBuilder, private logService: LoginService,
    private AuthPatient: AuthPatientService,
    private AuthProfessionnel: AuthProfessionnelService,
    private dialog: MatDialog,
    private router: Router) {

  }
 

  ngOnInit() {
    this.registerFormPro = this.formBuilder.group({

      name: ['', [Validators.required, Validators.minLength(2)]],
      lastname: ['', [Validators.required, Validators.minLength(2)]],
      birthday: ['', [Validators.required,pastDateValidator(),]],
      tel: ['', [
        Validators.required,
        telFaxLengthValidator(),
        Validators.pattern(/^\d+$/) // Allow any number of digits
      ]],
    
      fax: ['', [
        Validators.required,
        telFaxLengthValidator(),
        Validators.pattern(/^\d+$/) // Allow any number of digits
      ]],
      adresse: ['', [Validators.required, Validators.minLength(2)]],
      job: ['', Validators.required],
      // adeli: ['', Validators.required, Validators.pattern("^[0-9]*$")],
      rpps: ['', [Validators.required, rppsLengthValidator()]],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [
        Validators.required,
        Validators.minLength(8),
        Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/)
      ]],       confirmPassword: ['', [Validators.required,this.checkPassword ]],
      role: ['2',],
      consontement: [false, Validators.requiredTrue],
      title: ['', Validators.required],
    }, {
      validator: MustMatch('password', 'confirmPassword')
    });
    this.registerFormPro.valueChanges.subscribe(() => {
  
    });

    this.registerFormPat = this.formBuilder.group({

      name: ['', [Validators.required, Validators.minLength(2)]],
      lastname: ['', [Validators.required, Validators.minLength(2)]],
      birthday: ['', [Validators.required,pastDateValidator()]],
      tel: ['', [
        Validators.required,
        telFaxLengthValidator(),
        Validators.pattern(/^\d+$/) // Allow any number of digits
      ]],
      adresse: ['',[Validators.required, Validators.minLength(2)]],
      ssn: ['', [Validators.required, ssnLengthValidator()],Validators.pattern(/^\d{1,3}$/)],
      gender: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [
        Validators.required,
        Validators.minLength(8),
        Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/)
      ]],      confirmPassword: ['', [Validators.required,this.checkPassword ]],
      consontement: [false, Validators.requiredTrue],
      mailConfirmation: [true,],
      weight: [false, [ Validators.required,
        weightRangeValidator(),
        Validators.pattern(/^\d+$/)]],
      size: [false, [ Validators.required,
        sizeRangeValidator(),
        Validators.pattern(/^\d+$/)]],
    }, {
      validator: MustMatch('password', 'confirmPassword')
    });


    this.loginFormPro = this.formBuilder.group({

      email: ['', Validators.required],
      password: ['', Validators.required],
    });

    this.loginFormPat = this.formBuilder.group({

      email: ['', Validators.required],
      password: ['', Validators.required],
    });

  }

  get f() { return this.registerFormPro.controls; }
  get fPat() { return this.registerFormPat.controls; }
  get flogPro() { return this.loginFormPro.controls; }
  get flogPat() { return this.loginFormPat.controls; }
  checkPassword(control) {
    let enteredPassword = control.value
    let passwordCheck = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
    return (!passwordCheck.test(enteredPassword) && enteredPassword) ? { 'requirements': true } : null;
  }


  public onValChange2(val: any) {
    this.selectedVal2 = val;
/*     console.log(this.selectedVal2) */
    this.status = (this.action + this.selectedVal2)


  }




  /**
   * Registers a professional.
   * @param info Information about the professional.
   * @param type Type of role.
   */

  registerPro(info: any, type) {
    this.submitted = true;
  
    if (this.registerFormPro.invalid) {
      return;
    }
  
    if (type == 1) {
      this.AuthProfessionnel.registerProf(info)
        .subscribe(response => {
          this.showSuccessMessage();
          location.reload(); // Reload the page after successful registration
        }, error => {
          this.showErrorMessage();
        });
    } else {
      const doctorData = this.prepareDoctorData(info.value);
  
      this.AuthProfessionnel.registerProf(doctorData)
        .subscribe(response => {
          this.showSuccessMessage();
          location.reload(); // Reload the page after successful registration
        }, error => {
          this.showErrorMessage();
        });
    }
  }
  

  private prepareDoctorData(formData: any): any {
    const doctor = {
      name: formData.name,
      lastname: formData.lastname,
      email: formData.email,
      birthday: this.datePipe.transform(formData.birthday, 'yyyy-MM-dd'),
      adresse: formData.adresse,
      tel: formData.tel,
      password: formData.password,
      added_date: this.datePipe.transform(this.myDate, 'yyyy-MM-dd'),
      job: formData.job,
      fax: formData.fax,
      gender: formData.gender,
      adeli: formData.adeli,
      rpps: formData.rpps,
      role: "2",
      title: formData.title,
      consontement :formData.consontement

    };

    return doctor;
  }


  openAlertDialog() {
    /*  console.log( this.registerFormPro.value.title      );   */
     if(this.selectedVal2=="Professionnel"){
     const dialogRef = this.dialog.open(SignatureComponent,{
       data:{
         message: this.registerFormPro.value.title  ,
         type: 'pro',
         data:this.registerFormPro.value,
         buttonText: {
           cancel: 'Done'
         }
       },
     });
     dialogRef.afterClosed().subscribe((confirmed) => {
       if(confirmed)
       if(confirmed.result){
         this.acceptTerme=true;
         this.registerFormPro.patchValue({ consontement: true });
        }
     });}else{
       const dialogRef = this.dialog.open(SignatureComponent,{
         data:{
           message: 'Patient',
           type: 'pat',
           data:this.registerFormPat.value,
           buttonText: {
             cancel: 'Done'
           }
         },
       });
       dialogRef.afterClosed().subscribe((confirmed) => {
         if(confirmed)
         if(confirmed.result){
          this.acceptTerme=true;
          this.registerFormPat.patchValue({ consontement: true });

         }
         
       });
     }
   }
  // *********register patient****************//

 // Modify the preparePatientData function to include the 'consontement' field
 private preparePatientData(): any {
  const patient = {
    name: this.registerFormPat.value.name,
    lastname: this.registerFormPat.value.lastname,
    email: this.registerFormPat.value.email,
    birthday: this.datePipe.transform(this.registerFormPat.value.birthday, 'yyyy-MM-dd'),
    adresse: this.registerFormPat.value.adresse,
    tel: this.registerFormPat.value.tel,
    password: this.registerFormPat.value.password,
    added_date: this.datePipe.transform(this.myDate, 'yyyy-MM-dd'),
    gender: this.registerFormPat.value.gender,
    archived: false,
    account_state: true,
    ssn: this.registerFormPat.value.ssn,
    weight: this.registerFormPat.value.weight,
    size: this.registerFormPat.value.size,
    consontement: this.registerFormPat.value.consontement,
    mailConfirmation:true
  };

  return patient;
}

// Modify the registerPat function to use the updated preparePatientData function
registerPat() {
   if  (this.registerFormPat.invalid) {
    this.showErrorMessage();
  } else {
   this.AuthPatient.registerPatient(this.preparePatientData()).subscribe(
      () => {
        // Success action (if needed)
        this.showSuccessMessage();
        location.reload(); // Reload the page after successful registration
      },
      (error) => {
        // Error handling (if needed)
        console.error(error); // Log the error for debugging
        this.showErrorMessage();
        location.reload(); // Reload the page after successful registration

      }
    );
  }

}



  private showSuccessMessage() {
    const successMessage = localStorage.getItem("langauage") === 'fr'
      ? 'Inscription réussie'
      : 'Successful registration';

    Swal.fire({
      position: 'center',
      icon: 'success',
      title: successMessage,
      showConfirmButton: false,
      timer: 2000
    });
  }

  private showErrorMessage() {
    const errorMessage = localStorage.getItem("langauage") === 'fr'
      ? 'Inscription non réussie'
      : 'Registration not successful';

    Swal.fire({
      position: 'center',
      icon: 'warning',
      title: errorMessage,
      showConfirmButton: false,
      timer: 1500
    });
  }


  // *********login professionnel****************//
 
  loginPro(loginFormPro: any) {
    // Extract the form data
    let data = loginFormPro.value;
  
    // Send a login request to the server
    this.AuthProfessionnel.loginSPro(data).subscribe(data => {
      // If login is successful, save the token and navigate to the professional's forms
      this.datatoken = data;
      this.AuthProfessionnel.saveDataPro(this.datatoken.token);
      this.router.navigate(['/professionnel/formulaires']);
    }, (err: HttpErrorResponse) => {
      // If there's an error in the login request
      if (localStorage.getItem("langauage") === 'fr') {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'E-mail ou mot de passe non valide',
          showConfirmButton: false,
          timer: 2000
        });
      } else {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Email or password invalid',
          showConfirmButton: false,
          timer: 2000
        });
      }
  
      // Store the error message
      this.messageError = err.error.error;
    });
  }
  

  // *********login patient****************//


  loginPat(loginFormPat: any) {
    // Extract the form data
    let data = loginFormPat.value;
  
    // Send a login request to the server
    this.AuthPatient.loginSPat(data).subscribe(data => {
      // If login is successful, save the token and navigate to the patient's profile
      this.datatoken = data;
      this.AuthPatient.saveDataPat(this.datatoken.token);
      this.router.navigate(['/patient/profil']);
    }, (err: HttpErrorResponse) => {
      // If there's an error in the login request
      this.messageError = err.error.error;
  
      // Show an error message to the user
      if (localStorage.getItem("langauage") === 'fr') {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'E-mail ou mot de passe non valide',
          showConfirmButton: false,
          timer: 2000
        });
      } else {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Email or password invalid',
          showConfirmButton: false,
          timer: 2000
        });
      }
    });
  }
  

 /**
   * Resets form and submission status.
   */
  onReset() {
    this.submitted = false;
    this.registerFormPro.reset();
    this.registerFormPat.reset();

  }

 /**
   * Toggles password visibility.
   */
  myFunction() {
    this.hide = !this.hide;
  }
   /**
   * Callback for password strength change.
   * @param strength Strength value.
   */
  onStrengthChanged(strength: number) {
    // You can leave it empty if you don't need to use the strength value.
  }

    /**
   * Formats input for a specific field in a form group.
   * @param form The form group.
   * @param field The field name.
   */
  formatInput(form: FormGroup, field: string) {
    const fieldValue = form.get(field).value;
    form.get(field).setValue(fieldValue.trim());
}
  
}






