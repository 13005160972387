import { Injectable } from '@angular/core';
import { HttpClient,  HttpEvent,  HttpHandler,  HttpHeaders, HttpParams, HttpRequest  } from '@angular/common/http';
import { environment } from '../../../../environments/enviroment';
import { Observable } from 'rxjs/internal/Observable';
import {tap} from 'rxjs/operators';
import { Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class UpdProfilPatientService {
URL=environment.urlBackend
_uploadImage:any

token:any=localStorage.getItem('token_Pat')

header=new HttpHeaders().set('authorization',this.token)

  constructor(private http:HttpClient) {
  }
  private _refreshNeeded$ = new Subject<void>();
   get refreshNeeded$() {
     return this._refreshNeeded$;
   }



  updatePatient(id:any,body:any){


 const token=localStorage.getItem('token_Pat')
 const headers=new HttpHeaders().set('authorization','Bearer '+token)


    return this.http.put<any>(`${this.URL}`+'patient/'+id,body,{headers:headers});
  }

  updPhotoPat(id:any,body:any){


    const token=localStorage.getItem('token_Pat')
    const headers=new HttpHeaders().set('authorization','Bearer '+token)


       return this.http.put<any>(`${this.URL}`+'patient/updatephoto/'+id,body,{headers:headers});

     }


    getPatient(id:any){
      const token=localStorage.getItem('token_Pat')
      const headers=new HttpHeaders().set('authorization','Bearer '+token)

      return this.http.get<any>(`${this.URL}`+'patient/'+id,{headers: headers})   }


      uploadImage(image){

        /*   console.log("iiii",image) */
      
          return this.http.post(`${this.URL}`+"uploadApi/", {image :image} );
      
        }
          
  
  
        updateDoctorPhoto(id:any, photo:any){
  
  
        
            return this.http.put(`${this.URL}`+'patient/updatephoto/' +id , photo);
        
          }

          getAllPatient(){
            const token=localStorage.getItem('token_Pro')
            const headers=new HttpHeaders().set('authorization','Bearer '+token)
        
            return this.http.get<any>(`${this.URL}`+'patient/',{headers: headers}).pipe(
              tap(() =>  {
           
                this._refreshNeeded$.next();
              })
            );  
            }



          

        updatePasswordOnly(id,body){
          return this.http.put<any>(`${this.URL}patient/update/passwordonly/${id}`,body)
        }

        updateDataOnly(id,body){
          return this.http.put(`${this.URL}patient/update/dataonly/${id}`,body)
        }
            
        }
        



