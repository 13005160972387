import { AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms';

export function pastDateValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const selectedDate = new Date(control.value);
    const currentDate = new Date();

    const minDate = new Date();
    minDate.setFullYear(currentDate.getFullYear() - 18);

    if (selectedDate >= minDate) {
      return { pastDate: true };
    }

    return null;
  };
}
