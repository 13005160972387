<!-- ======= Top Bar ======= -->
<div id="topbar" class="d-flex align-items-center fixed-top">
  <div class="container d-flex justify-content-between">
    <div class="contact-info d-flex align-items-center">
      <!-- Add any contact information here if needed -->
    </div>

<!-- language-selector.component.html -->
<div class="container">
  <mat-form-field appearance="fill">
    <mat-select [(ngModel)]="languageSelect"(ngModelChange)="onLangChange($event)">
      <mat-select-trigger>
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
          <img [src]="getFlagIcon(languageSelect)" alt="Flag" class="flag-icon" />
          <span>{{ getLanguageLabel(languageSelect) }}</span>
        </div>
      </mat-select-trigger>
      <mat-option *ngFor="let lang of languages" [value]="lang.value">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
          <img [src]="lang.flagIcon" alt="Flag" class="flag-icon" />
          <span>{{ lang.label }}</span>
        </div>
      </mat-option>
    </mat-select>
  </mat-form-field>
  <div class="d-lg-flex social-links align-items-center">
    <a href="https://www.facebook.com/profile.php?id=100063058550130" class="facebook"><i class="fab fa-facebook-f fa-lg"></i></a>
    <a href="https://www.instagram.com/stimed_france/" class="instagram"><i class="fab fa-instagram fa-lg"></i></a>
  </div>
  
</div>


  
  </div>
</div>


<!-- ======= Header ======= -->
<!-- ======= Header ======= -->
<!-- ======= Header ======= -->
<header id="header" class="fixed-top">
  <div class="container d-flex align-items-center">
    <i><img src="../../../assets/img/logo.png " style="height: 70px ;width: 70px;padding-right: 10PX;" alt=""></i>
    <h1 class="logo me-auto"><a>Scores</a></h1>
  
    <nav class="navbar" *ngIf="!isSmallScreen">
      <ul>
        <li><a class="nav-link" routerLink="/">{{ 'HOMES.HOME' | translate }}</a></li>
        <li><a class="nav-link" routerLink="about" routerLinkActive="active">{{ 'HOMES.APROP' | translate }}</a></li>
        <li><a class="nav-link" routerLink="contact" routerLinkActive="active">{{ 'HOMES.CONTACT' | translate }}</a></li>
        <li>
          <ng-container *ngIf="!status; else Unlog">
            <button mat-raised-button color="primary" (click)="openDialog()">
              <mat-icon>login</mat-icon>
              {{ 'AUTH.IDENTIFIER' | translate }}
            </button>
          </ng-container>
        </li>
      </ul>
    </nav>

  <nav class="navbar" *ngIf="isSmallScreen">
  <div class="logo">
    <!-- Your logo or brand name here -->
  </div>
  <button mat-icon-button [matMenuTriggerFor]="menu">
    <mat-icon>menu</mat-icon> <!-- Use an appropriate icon, or add your own icon -->
  </button>
  <mat-menu #menu="matMenu">
    <ng-container *ngIf="!status; else Unlog">
      <button mat-menu-item (click)="openDialog()">
        <mat-icon>login</mat-icon>
        {{ 'AUTH.IDENTIFIER' | translate }}
      </button>
    </ng-container>
    <a mat-menu-item routerLink="/" routerLinkActive="active">{{ 'HOMES.HOME' | translate }}</a>
    <a mat-menu-item routerLink="about" routerLinkActive="active">{{ 'HOMES.APROP' | translate }}</a>
    <a mat-menu-item routerLink="contact" routerLinkActive="active">{{ 'HOMES.CONTACT' | translate }}</a>
  </mat-menu>
</nav>
    <!-- .navbar -->

    <!-- <a (click)="openDialog()" data-toggle="modal" data-target=".login-register-form" class="appointment-btn scrollto"
        ><span class="d-none d-md-inline">SignIn / SignUp</span></a> -->
  
    
    <ng-template #Unlog>
      <div class="login-button" *ngIf="whois ;else PtientUer">
        <a routerLink="./professionnel/profil" class="appointment-btn scrollto" style="font-weight: bold;font-size: 100%;color: white;">{{ 'HOMES.ESPACEPRO' | translate }}</a>
      </div>
      <ng-template #PtientUer>
        <a routerLink="./patient/profil" class="appointment-btn scrollto" style="font-weight: bold;font-size: 100%;color: white;">{{ 'HOMES.ESPACEPATIENT' | translate }}</a>

      </ng-template>

    </ng-template>


    <!-- <a routerLink="login" class="appointment-btn scrollto"><span class="d-none d-md-inline">register</span></a> -->
  </div>
</header>



<!-- End Header -->

<main id="main">
  <router-outlet></router-outlet>
</main>
