import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/enviroment';

@Injectable({
    providedIn: 'root'
  })
export class PasswordResetService {
  private apiUrl = environment.urlBackend; // Define your API URL in environment.ts

  constructor(private http: HttpClient) {}

  resetPassword(userType:string , userId: string, token: string, newPassword: string) {
    // Define the request body with userId and newPassword
    const requestBody = { password:newPassword };

    return this.http.post<any>(
      `${this.apiUrl}${userType==='prof'?'doctor':'patient'}/reset-password/${userId}/${token}`,
      requestBody,
    ).pipe(
      tap(() => {
        // Implement any necessary actions on success
        // For example, you can emit an event or perform a redirect
      })
    );
  }
}
