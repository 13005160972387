<div class="table-container">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a class="form-title">{{ 'LISTPATIENT.LISTPATIENT' | translate }}</a>
      </li>
    </ol>
  </nav>
  <div class="example-header container-with-space" fxLayout="row" fxLayoutAlign="space-between start">
    <mat-form-field>
      <input matInput (keyup)="applyFilter($event.target.value)" [placeholder]="'LISTPATIENT.SEARCH' | translate">
    </mat-form-field>
  
    <button mat-button class="top-buttons" (click)="openDialog()"> <mat-icon>add</mat-icon> <span>{{"BUTTONS.addaccount" | translate}}</span>
    </button>

    <button class="top-buttons"  mat-button (click)="toggleInvitations()" [ngClass]="showPendingInvitations ? 'pending-button' : 'all-button'">
      <ng-container *ngIf="!showPendingInvitations; else showAllIcon">
        {{"INVITATION.INVPEN" | translate}}
        <mat-icon>hourglass_empty</mat-icon>
      </ng-container>
      <ng-template #showAllIcon>
        {{"INVIT.ALL" | translate}}
        <mat-icon>list</mat-icon>
      </ng-template>
    </button>
    

  </div>
  

  <div class="example-container mat-elevation-z8">
    <mat-table [dataSource]="dataSource" matSort class="mat-table">
 
      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{"PROFILE.NAME" | translate}} </mat-header-cell>
        <mat-cell *matCellDef="let row" data-label="name"> {{ row.name }} {{ row.lastname }} </mat-cell>
      </ng-container>

      <!-- Adresse Column -->
      <ng-container matColumnDef="adresse">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{"PROFILE.ADRESS" | translate}} </mat-header-cell>
        <mat-cell *matCellDef="let row" data-label="adresse"> {{ row.adresse }} </mat-cell>
      </ng-container>

      <!-- Email Column -->
      <ng-container matColumnDef="email">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{"PROFILE.EMAIL" | translate}}  </mat-header-cell>
        <mat-cell *matCellDef="let row" data-label="email"> {{ row.email }} </mat-cell>
      </ng-container>

      <!-- Télé Column -->
      <ng-container matColumnDef="tel">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{"PROFILE.TELE" | translate}}  </mat-header-cell>
        <mat-cell *matCellDef="let row" data-label="tel"> {{ row.tel }} </mat-cell>
      </ng-container>

<ng-container matColumnDef="invitation">
  <mat-header-cell *matHeaderCellDef> Invitation </mat-header-cell>
  <mat-cell *matCellDef="let row" data-label="invitation">
    <button
     
      class="top-buttons"
      (click)="addPatient(row._id, row)"
      [disabled]="row.invitationState === 'invitation_pending' || row.invitationState === 'invitation_accepted'"
    >
      <mat-icon [style.color]="getButtonBackgroundColor(row.invitationState)" >{{ getButtonIcon(row.invitationState) }}</mat-icon>
      <span>{{ getButtonText(row.invitationState) }}</span>
    </button>
  </mat-cell>
</ng-container>

    
<button  class="top-buttons" (click)="openDialog()"> 
  <mat-icon>add</mat-icon>
  <span>{{"BUTTONS.addaccount" | translate}}</span>
</button>
    
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>

    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="10"></mat-paginator>

  </div>
  
</div>